.vve-group-detail-card {
    font-size: 1.5rem;

    .vve-download-btn {
        border-radius: 50rem;
        background-color: $download-btn;
        font-variant-caps: all-small-caps;
        padding: 0.2rem 3rem;
        width: auto;
        color: $vve-white;
        border: none;
    }
    .vve-download-btn-div {
        text-align:right
    }
}