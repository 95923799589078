.vve-language-list {
    height: calc(100% - 14.8rem);
    overflow-y: auto;
    button {
        color: $vve-black;
        @extend .vve-medium;
        font-weight: 700;
        border: none;
        &.active {
            background: $vve-vivid-blue;
            border-radius: 1rem;
        }
    }
}