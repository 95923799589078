.vve-back-btn-oncall {
  display: none;

  .vve-back-arrow-icon {
    background-image: url("../../assets/images/back-arrow.svg");
    width: 14px;
    height: 14px;
    float: left;
    margin-right: 5px;
  }

  .vve-back-arrow-text {
    font-size: 16px;
    color: $vve-tabs-color-blue-active;
    line-height: 14px;
  }
}

@media ($mobile) {
  .vve-back-btn-oncall {
    display: block;
    margin: 10px auto 18px 16px;
  }
}