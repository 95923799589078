.vve-groupclass-cards-container{
    .card{
        margin-right: 1.2rem;   
        border-style: solid;
        border-color: $vve-department-list-group;
        border-width: 0.1rem 0px 0.1rem 0px;
        border: none;
    }
    .card-title {
        @extend .vve-bold;
        line-height: 1.5rem;
        font-size: 1.3rem;
    }
    .vve-group-class-text span{
        line-height: 1.5rem;
    }
    .vve-text-bold{
        @extend .vve-bold;
    }
    .vve-group-class-text {
        color: #70788a;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.6rem;
        padding-bottom: 0.5rem;
    }
    .card-body{
        padding-top: 0px;
        padding-bottom:0px ;
        padding-left: 1rem;
    }
    hr{
        width:98%;
    }
}

