.vve-toggle-container {
  margin-top: 3px;

  .vve-partition {
    width: 50%;
    float: left;
    font-family: 'Lato-Regular';
    font-size: 13px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: -0.02em;

    @media ($mobile) {
      margin-left: 6.5%;
      line-height: 0px;
    }

    .vve-img-Vertical {
      vertical-align: sub;
    }
  }

  .vve-fav-div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 32px;
    margin-bottom: 5px;
    gap: 10px;

    @media ($mobile) {
      width: 95%;
    }
  }

  .vve-fav-label {
    font-family: 'Lato-Regular';
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-align: left;
  }

  .vve-slider.vve-round {
    border-radius: 34px;

    &:before {
      border-radius: 50%;
    }
  }

  .vve-search-filter-disable {
    @media ($mobile) {
      margin: 16px 19px 7px 19px;
    }
  }

  .vve-switch {
    position: relative;
    display: inline-block;
    width: 39.48px;
    height: 24px;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:disabled {
        cursor: no-drop;
      }
    }
  }


}

.vve-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $vve-fav-background;
  transition: .4s;

  &:before {
    position: absolute;
    content: "";
    height: 20.9px;
    width: 20.9px;
    left: 2.5px;
    top: 1.55px;
    background-color: $vve-white;
    transition: .4s;
  }

  input:checked+& {
    background-color: $vve-fav-toggle-backround;
  }

  input:focus+& {
    box-shadow: 0 0 1px $vve-fav-toggle-backround;
  }

  input:checked+&:before {
    transform: translateX(13px);
  }
}

.vve-nofav-modal .modal-title {
  width: 100%;
}