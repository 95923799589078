.vve-error-container {
  position: relative;
  display: flex;
  flex-direction: column;

  .vve-unauthorized-logo {
    background-repeat: no-repeat;
    height: 31.6rem;
    background-position: center;
    background-size: contain;
    width: 100%;
    margin-top: 7.5rem;

    @media($mobile) {
      height: 13.4rem;
      margin-top: 3rem;
    }
  }
}