.vve-caregiver-list {
    margin-top: 29px;
    overflow-y: auto;
    height: 468px;

    @media (orientation: portrait) {
        max-height: calc(100vh - 380px);
    }

    .vve-caregiver-panel {
        line-height: 11px;

        @media ($mobile) {
            width: 74vw;
        }

        .vve-caregiver-name {
            font-size: 16px;
            margin: 0;
            display: flex;
            height: 20px;
            line-height: 14px;

            p {
                font-family: 'Lato-Bold';
                font-weight: 500;
                color: $vve-black;
            }

            p:first-child {
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 180px;
                overflow: hidden;
                height: 100%;
                color: $vve-black;

                @media ($mobile) {
                    max-width: none;
                }
            }

            .vve-caregiver-oncall {
                display: none;
            }

        }

        &.vve-caregiver-oncall-live {
            .vve-caregiver-oncall {
                background: $vve-oncall-green;
                border-radius: 7px;
                color: $vve-white;
                display: block;
                font-size: 10px;
                height: 15px;
                margin-left: 0.5rem;
                padding-left: 0.35rem;
                padding-right: 0.35rem;
                position: relative;
            }

            .vve-caregiver-oncalldatetime {
                color: $vve-oncall-green;
                font-family: 'Lato-Bold';
                line-height: 10px;
                position: relative;
                bottom: 2px;
            }
        }

        .vve-caregiver-role {
            font-size: 11px;
            margin: 0;
            line-height: 16px;
            color: $vve-caregiver-role-grey;
            font-family: 'Lato-Bold';
            font-weight: 500;
        }

        .vve-caregiver-callorder {
            font-family: 'Lato-Bold';
            font-size: 11px;
            margin: 0;
            color: $vve-caregiver-role-grey;
            line-height: 16px;
            font-weight: 500;
        }

        .vve-caregiver-oncalldatetime {
            font-size: 12px;
            margin: 0;
            font-family: 'Lato-Bold';
            line-height: 10px;
            position: relative;
            bottom: 2px;
            color: $vve-black;
            font-weight: 500;
        }

        .vve-caregiver-role-order-text {
            position: relative;
            bottom: 5px;
        }

    }

    .vve-oncall-selected-caregiver {
        background-color: $vve-oncall-selected-dept;
    }

    .vve-caregiver-icon-container {

        width: 48px;
        height: 48px;

        &.vve-caregiver-oncall-live {
            background: $vve-oncall-green;
            border-radius: 50%;

            .vve-caregiver-icon {
                width: 44px;
                height: 44px;
                border: 2.5px solid $vve-white;
                border-radius: 50%;
                margin: 2px;
            }
        }

        .vve-caregiver-icon {
            width: 40px;
            height: 40px;
            margin: 3px;
            border-radius: 50%;
        }
    }

    /* overrides */
    .list-group-item:first-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .list-group-item {
        border-left: none;
        border-right: none;

        &.list-group-item-action {
            padding-left: 0;
            padding-right: 0;

            .align-items-center {
                padding-left: 11px;
                padding-right: 11px;
            }
        }
    }
}

.vve-caregiver-no-results-found {
    font-size: 18px;
    font-weight: 600;
    height: 50vh;
    display: flex;
    align-items: center;
    color: $vve-noresult-found-text;

    p {
        margin: auto;
    }
}

.vve-caregiver-list-container {
    height: 468px;
}