.vve-add-support-container {
    .vve-support-main-header {
        @extend .vve-bold;
        font-size: 1.2rem;
    }

    .vve-support-header {
        @extend .vve-bold;
        font-weight: 800;
        font-size: 1rem;
    }

    .vve-support-content {
        @extend .vve-medium;
        font-size: 1rem;
        margin-bottom: 0.2rem;
    }

    .vve-support-info {
        @extend .vve-lightitalic;
        font-size: 1rem;
    }

    .vve-text-right {
        text-align: right;
    }
    .vve-text-alignment {
        position: relative;
        bottom: 0.5rem;
    }
    .vve-landscape-snowcontainer {
        overflow-y: auto;
        padding: 2px 6px;
        overflow-x: hidden;
        height: 65vh;
    }
    .vve-text-copied {
        display: inline-flex !important;
        color: $vve-white;
        background-color: $vve-black;
    }
}