.vve-text-copied {
    display: inline-flex;
    padding: 10px 14px;
    border-radius: 20px;
    box-shadow: 0px 2px 10px 0px rgba(151, 151, 151, 0.25);
    font-weight: 500;
    line-height: 19px;
    color: $vve-white;
    background-color: $vve-black;
    margin-left: auto;
    margin-right: 20px;

    @media ($mobile) {
        /* Need to hide as we have common component for mobile */
        display: none;
    }

    &.vve-mobile-only {
        /* hidden for desktop */
        display: none;

        @media ($mobile) {
            position: absolute;
            display: inline-flex;
            font-size: 13px;
            left: 50%;
            transform: translateX(-50%);
            margin-top: -19px;
            right: unset;
        }
    }
}

.vve-clinician-info-container {
    .vve-mobile-only {
        @media ($mobile) {
            margin-top: 30px;
        }
    }
}