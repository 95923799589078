.header-container {
  display: flex;
  width: 100%;

  .vve-launcher-bg {
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    height: 100%;
    width: 100%;
    background-position: top left;
  }

  .vve-header-Logo {
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    height: 4.6rem;
    margin: 2rem;
    z-index: 1;
    @media($mobile) {
      height: 3.5rem;
      background-position: center;
      margin: 2rem 2rem 1.6rem 2rem;
    }
  }
}