.vve-information-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 3.4rem;

    @media($mobile) {
        padding: 0 3.7rem 0 2.7rem;
        margin-top: 2.4rem;
    }

    .vve-information-header {
         font-size: 4.2rem;
         line-height: 4.2rem;
         letter-spacing: -0.091rem;
         color: $error-page-color;
         font-weight: 325;
        @extend .vve-kpbook;

        @media($mobile) {
             font-size: 2.8rem;
             line-height: 3.36rem;
        }
    }

    .vve-information-body {
         margin-top: 2rem;
         font-size: 2.8rem;
         line-height: 2.8rem;
         letter-spacing: -0.091rem;
         color: $error-page-color;
         font-weight: 300;
        @extend .vve-kplight;

        @media($mobile) {
             margin-top: 1.4rem;
             width: 72%;
             font-size: 1.5rem;
             line-height: 2.16rem;
        }
    }
}