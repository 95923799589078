.vve-group-class-table {
    font-size: 1.5rem;

    .vve-divider {
        background-color: $divider;
        margin: 1px;
    }
    .vve-divider-bottom {
        margin-bottom: 1rem;
    }
    .vve-divider-top {
        margin-top: 2rem;
    }
    .vve-group-class-table {
        border-left: 0px $vve-white;
        border-right: 0px $vve-white;
    }
    .vve-group-class-table thead {
        font-variant-caps: all-small-caps;
        font-weight: 600;
        font-size: 1.5rem;
    }
    .vve-group-class-table tbody {
        font-size: 1.3rem;
    }
    .vve-group-class-table thead {
        position: sticky;
        top: -1px;
        background: $vve-white;
        border-top: $vve-white;
        font-style: bolder;
    }
    .vve-text-center {
        text-align: center;
    }
    .vve-group-class-text {
        color: $group-class-text;
        font-size: 1.3rem;
        font-weight: 500;
    }
    .vve-text-bold {
        font-family: 'Lato-Bold';
    }
    .vve-date-text {
        color: $vve-black;
    }
    .vve-card-detail {
        margin-bottom: 1rem;
    }
    
    /* Bootstrap classes */
    .card-body {
        padding: 0.5rem 3rem;
        padding-bottom: 4rem;
    }
    .card-text {
        max-height: 40rem;
        overflow-y: auto;
    }
    .card {
        border-radius:1 rem;
        margin-top: 1rem;
        margin-bottom: 2rem;
        box-shadow: 0 3px 6px 1px $divider
    }
    .table-bordered>:not(caption)>*>* {
        border-width: 0px;
    }
    .card-header {
        text-align: center;
        margin-bottom: 1rem;
    }
}