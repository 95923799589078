.vve-report-filter-date-range {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 2%;
    margin-left: -3%;
    @media($mobile) {
        flex-direction: column;
        gap: 10px;
        margin: 0%;
    }

    .vve-date-picker-separator {
        color: $button-disabled-font;
    }

    .vve-date-picker-input {
        height: 32px;
        width: 72%;
        border: 1px solid $vve-filter-grey;
        border-radius: 8px;
        padding-left: 8px;
    }

    .vve-calendar-icon-custom {
        right: 34px;
        top: 7px;
        height: 6px !important;
    }

    .vve-date-picker-start .vve-date-picker-end{
        width: 25%;
    }

    .vve-calendar-container-start {
        left: 120px !important;
        @media($mobile) {
            left: 0 !important;
        }
    }

    .vve-calendar-container-end {
        left: 120px !important;
        @media($mobile) {
            left: 0 !important;
        }
    }

    .vve-calendar-body {
        font-size: 1.1rem;
        border-radius: 10px;
        
        .react-datepicker__header {
            background-color: inherit;
            border-bottom: none;
            border-top-left-radius: none;
        }

        .react-datepicker__day-name {
            color: $vve-date-picker-day-name-color;
        }

        .react-datepicker__day--selected {
            background-color: $vve-vivid-blue !important;
        }

        .vve-calendar-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 3px;
            margin-top: -6px;
            .vve-calendar-month {
                @extend .vve-bold;
                width: 46%;
                margin-left: -4%;
            }
            .vve-calendar-navigation {
                display: flex;
                flex-direction: row;
                gap: 4px;
                margin-right: 2%;
            }
            .vve-calendar-icon-nav {
                height: 8px;
                background-color: $vve-white;
            }
        }
    }
}