.vve-clinician-info-container {
  position: relative;
  width: 680px;
  height: 821px;
  margin-left: 10px;

  @media ($mobile) {
    width: 100%;
    margin-top: 1rem;
    margin-left: 0px;
  }
}