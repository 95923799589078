.vve-tab-content-container {
    @extend .vve-bold;
    width: 35rem;
    @media screen and ($pixel-ratio: 1) and ($device-medium) {
        width: 100%;
    }
    padding: 1.2rem 1.6rem 0 1.6rem;
    height: calc(100% - 12vh);
    .vve-add-tabs {
        background: $vve-bluish-white;
        color: $vve-vivid-blue;
        border-radius: 1.1rem;
        box-shadow: 0px 1px 4px rgb(0 0 0 / 8%);
        padding: 0;
        margin-bottom: 0.6rem;

        .nav-item {
            border-radius: 1.1rem;
            border-bottom: 1px solid $vve-bluish-white;

            .vve-add-tabitem {
                @extend .vve-medium;
                font-weight: 700;
                color: $vve-bright-grey;
                line-height: 3rem;

                &.nav-link {
                        padding-left: 0.8rem;
                        padding-right: 0.8rem;
                        font-size: 1.1rem;
                        line-height: 2.4rem;
                        &.active {
                            border-radius: 1.1rem;
                            color: $kp-blue;
                        }
                    }
            }

            &:first-child {
                .vve-add-tabitem {
                    &:hover {
                        &:not(.active) {
                            border-top-left-radius: 1.5rem;
                            border-bottom-left-radius: 1.5rem;
                            border-color: transparent;
                        }
                    }
                }
            }

            &:nth-child(2) {
                .vve-add-tabitem {
                    &:hover {
                        &:not(.active) {
                            border-top-right-radius: 1.5rem;
                            border-bottom-right-radius: 1.5rem;
                            border-color: transparent;
                        }
                    }
                }
            }
        }
    }
    .vve-add-mobile-tabs {
        .nav-item {
            .vve-add-tabitem {
                &.nav-link {
                    @media screen and ($mobile-landscape){
                        height: 7vh;
                    }
                    height: 4vh;
                    line-height: 1.4rem;
                }
            }
        }
    }
    .tab-content {
        height: calc(100% - 4.4rem);
        font-size: 1.4rem;

        .vve-tab-panel {
            &.active {
                height: 100%;
            }
        }
        input[type="search"]::-webkit-search-cancel-button {
            -webkit-appearance: none;
            height: 1.5rem;
            width: 1.5rem;
            display: block;
            background-image: url("../../assets/images/vve-cancel-icon.svg");
            background-repeat: no-repeat;
            background-size: 1.5rem;
        }
    }

    @media($mobile) {
        align-self: center;
    }
    .vve-container-topmargin {
        margin-top: 2rem;
    }
}