.vve-groupclass-list-container {
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
    .vve-date-header-container {
        @extend .vve-bold;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1%;
        width: 95%;
        font-size: 17px;
    }
}

