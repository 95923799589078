i.vve-icon {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  line-height: 1.75;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  overflow: hidden;
}

.vve-magnifying-icon {
  background-size: 1.5rem 1.5rem;
  background-image: url("../images/vve-magnifier.svg");
  background-repeat: no-repeat;
  background-position: 1.5rem center;
}

.vve-clear-btn {
  cursor: pointer;
  border: none;
  background-color: $vve-white;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 2vh;
  width: 2rem;
  align-self: center;
  background-size: 2rem 2rem;
  background-image: url("../images/vve-clear-btn.svg");
}

.vve-copy-btn {
  cursor: pointer;
  border: none;
  background-color: $vve-white;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 1rem;
  width: 22px;
  align-self: center;
  background-image: url("../images/vve-copy-btn.svg");
}

.vve-back-caret-btn {
  cursor: pointer;
  border: none;
  background-color: $vve-white;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 1rem;
  width: 22px;
  position: relative;
  bottom: 0.4rem;
  align-self: center;
  background-image: url("../images/vve-back-caret.svg");
}

.vve-back-caret-btn:focus, .vve-back-caret-btn:focus-visible {
  outline: none;
}

.vve-caret-btn {
  cursor: pointer;
  border: none;
  background-color: $vve-white;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 1rem;
  width: 22px;
  align-self: center;
  background-image: url("../images/vve-caret-btn.svg");
}

.vve-dialpad-star {
  background: $vve-light-grey;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1.5rem 1.5rem;
  background-image: url("../images/vve-star.svg");
}

.vve-dialpad-hash {
  background: $vve-light-grey;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1.5rem 1.5rem;
  background-image: url("../images/vve-hash.svg");
}

.vve-header-Logo {
  background-image: url("../images/Kaiser-Horizontal-Logo.svg");
}

.vve-launcher-bg {
  background-image: url("../images/confirmation-background.svg");
}

.vve-unauthorized-logo {
  background-image: url("../images/vve-unauthorized.svg");
}

.vve-loader {
  background-image: url("../images/vve-spinnerLoader.svg");
  background-position: center;
  background-repeat: no-repeat;
  height: 4.8rem;
  width: 100%;
  background-size: contain;
}

.vve-date-filter {
  cursor: pointer;
  border: none;
  background-color: $vve-white;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 1rem;
  width: 2rem;
  margin-right: .5rem;
  align-self: center;
  background-image: url("../images/vve-filter.svg");
}

.vve-calendar-icon {
  cursor: pointer;
  border: none;
  background-color: $vve-white;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 1.5rem;
  width: 2rem;
  padding: 0rem 1rem;
  align-self: center;
  background-image: url("../images/vve-calendar.svg");
}

.vve-download-icon {
  cursor: pointer;
  border: none;
  background-color: $vve-white;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 1.5rem;
  width: 2.2rem;
  margin-left: 2rem;
  align-self: center;
  background-image: url("../images/vve-download-icon.svg");
}

.vve-calendar-icon-off {
  cursor: pointer;
  border: none;
  background-color: $vve-white;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 1.5rem;
  width: 2rem;
  padding: 0rem 1rem;
  align-self: center;
  background-image: url("../images/vve-calendar-off.svg");
}

.vve-calendar-icon-next {
  cursor: pointer;
  border: none;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 6px;
  min-width: 6px;
  align-self: center;
  background-image: url("../images/vve-calendar-next.svg");
}

.vve-calendar-icon-prev {
  cursor: pointer;
  border: none;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 6px;
  min-width: 6px;
  align-self: center;
  background-image: url("../images/vve-calendar-previous.svg");
}

.vve-calendar-icon-ellipse {
  border: none;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 6px;
  min-width: 6px;
  align-self: center;
  background-image: url("../images/ellipse.svg");
}