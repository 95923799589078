.vve-invisit-menu-container {
    width: 35rem;
    height: calc(100% - 12vh);

    @media screen and ($pixel-ratio: 1) and ($device-medium) {
        width: 100%;
    }

    @media ($mobile) {
        // margin: 0;
        width: 100%;
        // height: 82%;
    }
    
    .nav-pills .nav-link {
        color: $vve-black;
        font-size: 1.1rem;

        &.active {
            color: var(--bs-nav-pills-link-active-color);
            background: $vve-vivid-blue;
            border-radius: 16px;
        }
    }

    .vve-menu-nav {
        background: $vve-new-menu-bgcolor;
        margin-bottom: 0.6rem;
        padding: 10px;
    }

    .vve-menu-content {
        height: calc(100% - 4.4rem);
        font-size: 1.4rem;
        padding: 1%;

        .active-menu {
            height: 100%;
        }
    }
}

@media (orientation: landscape) {
    .vve-menu-content {
        height: calc(97% - 4.4rem) !important;
    }
}

div.vve-invisit-menu-container ~ div.vve-invite-btn-container {
    width: 100%;
    // height: 15%;
    // @media screen and ($pixel-ratio: 1) and ($device-medium) {
    //     width: 100%;
    // }
}

.vve-invisit-menu-mobile {
    align-self: center;
}

