.vve-invite-guest-text-container {
    display: flex;
    flex-direction: column;
    .vve-info {
        width: 100%;
        letter-spacing: -0.02em;
        text-align: left;
    }
    .vve-multi-invite-header {
        @extend .vve-bold;
        font-weight: 400;
        color: $vve-black
    }
    .vve-form-label {
        margin-bottom: 2rem;
        @extend .vve-medium;
        input:enabled:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 1000px $vve-white inset;
        }
        input:disabled:-webkit-autofill{
            -webkit-box-shadow: 0 0 0 1000px $input-disabled inset;
        }
        input{
            @extend .vve-medium;
            border-radius: 0.6rem;
            font-size: 1.3rem;
            line-height: 2rem;
        }
        .is-invalid {
            background-image: none !important;
            padding-right: 0.55em;
            color: $vve-bright-red;
            background-color: $vve-light-red;
        }
        input[type="search"][class~="is-invalid"]::-webkit-search-cancel-button {
            -webkit-appearance: none;
            height: 1.5rem;
            width: 1.5rem;
            display: block;
            background-image: url("/assets/images/vve-error-cancel-search.svg");
            background-repeat: no-repeat;
            background-size: 1.5rem;
            opacity: 0.5;
            cursor: pointer;
        }
        input[type="search"][class~="is-valid-item"]::-webkit-search-cancel-button {
            opacity: 1 !important;
            cursor: pointer;
        }
    }
    .vve-invitee-validation-error {
        @extend .vve-medium;
        color: #D00000;
        .error-image-circle {
            margin: -2px 5px 0 0px;
        }
    }
}