.vve-reports-container {
  margin: 1rem 0px;
  padding-right: 3rem;
  padding-left: 3rem;


  @media ($mobile) {
    margin: 0;
  }

  .vve-groupclass-card-list {
    overflow: hidden;
    overflow-y: scroll;
    height: 50vh;
  }
  .vve-text-right {
    text-align: right;
  }
  .vve-card {
    cursor: pointer;
    border-top: 1px solid $button-disabled-grey;
    padding-top: 1rem;
  }
  .vve-active-card {
    .card {
      background-color: $table-background !important;
    }
    .vve-download-icon {
      background-color: $table-background;
      background-image: url("../../assets/images/vve-download-icon.svg");
    }
  }
  
  .vve-group-class-message {
    width: 94%;
    border-top: 1px solid $button-disabled-grey;
    padding-top: 1rem;
    text-align: center;
    font-style: italic;
    font-size: 15px;
    color: $vve-caregiver-role-grey;
  }
}
