.nav-pills .nav-link {
    color: $vve-black;
    font-size: 1.1rem;
    &.active {
        background: $vve-vivid-blue;
        border-radius: 16px;
    }
}
.vve-new-menu {
    background: $vve-new-menu-bgcolor;
    margin-bottom: 0.6rem;
    padding: 10px 0 10px 19px;
}
.vve-portal-support-tab-content {
    padding: 1.2rem 1.6rem 0;
    width: 100%;
    max-width: 55rem;
}