.vve-join-container {

    .vve-kpgroup-logo {
        &.vve-redirection-logo {
            margin-top: 2.3rem;

            @media($mobile) {
                margin-top: 3.5rem
            }

        }
    }

    .vve-header-Logo {
        margin-left: 20px;
        margin-top: 20px;
        background-repeat: no-repeat;
        background-size: contain;
        height: 46px;
        @media($mobile) {
            align-self: center;
            background-image: url("../../assets/images/Kaiser-mobile-logo.svg");
            width: 230px;
            height: 30px;
        }
    }

    .vve-redirection-header {
        color: $very-dark-blue;
        text-align: center;
        font-size: 42px;
        font-weight: 325;
        font-style: normal;
        letter-spacing: -0.91px;
        font-family: 'Gotham 4r';

        @media($mobile) {
            font-size: 32px;
        }
    }

    .vve-redirection-body {
        font-size: 28px;
        margin-bottom: 17px;
        text-align: center;
        color: $redirect-text-color;
        font-family: 'Gotham 3r';
        font-weight: 325;

        @media($mobile) {
            font-size: 16px;
            font-weight: 300;
            line-height: 28px;
            letter-spacing: -0.91px;
            text-align: center;
            margin-left: 20px;
            margin-right: 20px;
            margin-top: 10px;

        }
    }
}

.header-container {
    .vve-launcher-bg {
        @media($mobile) {
            margin-top: 63px;
        }
    }
}