.vve-search-filter-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 8px;
  border: 1px solid $vve-department-list-group;
  border-radius: 8px;
  height: 32px;
  font-size: 13px;

  &:focus-within {
    border: 1px $vve-tabs-color-blue-active solid;
  }

  @media ($mobile) {
    margin: 16px 19px;
  }

  .vve-directory-search-container {
    width: 100%;

    input::placeholder {
      color: $vve-black;
    }

    .vve-directory-search-input {
      width: 100%;
      border: none;
      padding-left: 28px;
      background-color: $vve-white;

      &:focus {
        outline: 0;
      }

      &::-webkit-search-cancel-button {
        -webkit-appearance: none;
        background-image: url("../../assets/images/vve-cancel-search.svg");
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        cursor: pointer;
        height: 13px;
        width: 13px;
        margin-left: 4px;
      }
    }

    .vve-magnifying-icon {
      background-position: 0;
      background-size: 14px 14px;
    }
  }

  .vve-vertical-line {
    width: 2px;
    height: 100%;
    background-color: $vve-department-list-group;
  }

  .vve-filter-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 14px;
    cursor: pointer;

    .vve-filter-title {
      margin-right: 6px;
      color: $vve-black;

      @media ($mobile) {
        display: none;
      }
    }

    .vve-filter-icon {
      background-image: url("../../assets/images/signifier.svg");
      background-repeat: no-repeat;
      background-size: contain;
      position: relative;
      height: 12px;
      width: 15px;
    }
  }

}

.vve-search-filter-disable {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 8px;
  border: 1px solid $vve-department-list-group;
  border-radius: 8px;
  height: 32px;
  font-size: 13px;
  pointer-events: none;
  background: $input-disabled ;

  &:focus-within {
    border: 1px $vve-tabs-color-blue-active solid;
  }

  @media ($mobile) {
    margin: 16px 19px;
  }

  .vve-directory-search-container {
    width: 100%;

    input {
      background: $input-disabled ;
    }

    .vve-directory-search-input {
      width: 100%;
      border: none;
      padding-left: 28px;
      background-color: $input-disabled;

      &:focus {
        outline: 0;
      }

      &::-webkit-search-cancel-button {
        -webkit-appearance: none;
        background-image: url("../../assets/images/vve-cancel-search.svg");
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        cursor: pointer;
        height: 13px;
        width: 13px;
        margin-left: 4px;
      }
    }

    .vve-gray-magnifying-icon {
      background-position: 0;
      background-size: 14px 14px;
      background-image: url("../../assets/images/vve-gray-magnifier.svg");
      background-repeat: no-repeat;
    }
  }

  .vve-vertical-line {
    width: 2px;
    height: 100%;
    background-color: $vve-department-list-group;
  }

  .vve-filter-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 14px;
    cursor: pointer;

    .vve-filter-title {
      margin-right: 6px;
      color: $vve-grey;

      @media ($mobile) {
        display: none;
      }
    }

    .vve-filter-icon {
      background-image: url("../../assets/images/gray_signifier.svg");
      background-repeat: no-repeat;
      background-size: contain;
      position: relative;
      height: 12px;
      width: 15px;
    }
  }

}