/* Calendar container */
.vve-selected-department-container {
  height: 821px;
  width: 331px;

  @media ($mobile) {
    width: 100%;
  }

  .vve-oncall-calendar-container {
    width: 320px;
    color: $vve-black;

    @media ($mobile) {
      width: 100%;
      margin-top: 1rem;
    }

    .vve-oncall-clinicianlist-header {
      font-size: 16px;
      font-weight: 500;
      background-color: $vve-bgcolor-calendar;
      border-top-left-radius: 0.6rem;
      border-top-right-radius: 0.6rem;
      height: 34px;

      @media ($mobile) {
        border-top-left-radius: unset;
        border-top-right-radius: unset;
      }

      p {
        margin: auto;
        padding-top: 0.5rem;
        display: flex;
        justify-content: center;
        font-family: "Lato-Bold";
      }
    }

    .vve-oncall-current-date-header {
      font-size: 16px;
      height: 50px;
      font-weight: 500;
      margin-top: 1rem;
      justify-content: center;
      align-items: center;
      display: flex;
      font-family: "Lato-Bold";
    }

    .vve-oncall-calendar {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      max-width: 500px;
      /* Adjust as needed */
      margin: 0 auto;

      .vve-oncall-calendar-cell {
        height: 63px;
        width: 49px;
        text-align: center;
        border: none;
        /* Remove borders */
        border-radius: 5px;
        margin-left: 7px;
        margin-bottom: 13px;
        cursor: pointer;

        .vve-oncall-day {
          font-size: 12px;
          margin-bottom: 2px;
          text-transform: uppercase;
          color: $vve-calender-header-color;
        }

        .vve-oncall-selected-date {
          background-color: $vve-tabs-color-blue-active;
          color: $vve-oncall-selected-date-color;
          border-radius: 50%;
          font-family: "Lato-Bold";
        }

        .vve-oncall-date-month {
          height: 49px;

          .vve-oncall-month {
            font-size: 12px;
            padding-top: 3px;
          }

          .vve-oncall-date {
            font-size: 16px;
          }
        }

        .vve-oncall-current-day {
          border-radius: 50%;
          border: 2px solid $vve-tabs-color-blue-active;
        }
      }
    }
  }
}