

.vve-dropdown {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-top: 14.5px;

    label {
        margin-bottom: 5.5px;
    }

    .dropdown-header {
        color: $vve-black-btn-font;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5.5px;
        font-family: 'Lato-Bold';
        word-wrap: break-word;
    }

    .vve-select {
        background: $vve-white;
        border: 1px solid $vve-filter-grey;
        border-radius: 8px;
        width: 100%;
        overflow: hidden;
        background: url("/assets/images/Dropdown-Chevron.svg") no-repeat 98% $vve-white;
        display: flex;
        position: initial;
        @media($mobile) {
            height: 40px;
        }

        &.dropdown {
            .dropdown-toggle {
                width: 100%;
                text-align: left;
                color: $vve-black-btn-font;
                font-size: 14px;
                font-family: 'Lato-Regular';
                font-weight: 400;
                word-wrap: break-word;
                margin-right: 23px;
                border: none;
                text-overflow: ellipsis;
                overflow: hidden;

                &:after{
                    display: none;
                }
            }
        }

        &.show {
            background: url("/assets/images/Dropdown-Chevron-up.svg") no-repeat 98% $vve-white;
        }

        &.disabled {
            background-color: $disable-off-white;
            border: 1px solid $vve-department-list-group;
            color: $disable-off-white;
            background: url("/assets/images/chevron-gray.svg") no-repeat 98% $disable-off-white;

            .dropdown-toggle {
                color: $disable-off-white;
            }
        }

        select {
            width: 100%;
            font-family: 'Lato-Light';
            font-style: normal;
            font-weight: 900;
            font-size: 13.5px;
            padding: 5.5px;
            padding-right: 23px;
            border: 0;
            border-radius: 8px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background-color: transparent;

            &:disabled {
                background: $disable-off-white;
                color: $disable-off-white;
            }

            @media($mobile) {
                padding: 11px 19px;
                padding-right: 23px;
            }
        }
    }

    .dropdown-menu.show {
        position: absolute;
        background-color: $vve-white;
        width: 100%;
        top: 15px;
        max-height: 300px;
        overflow: auto;

        @media ($mobile) {
            max-height: 265px;
            &.vve-department-dropdown {
                max-height: 190px;
            }
        }

        .dropdown-item {
            color: $vve-black;
            font-family: Lato-Regular;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: -0.24px;
            line-height: 18px;
            text-align: left;
            padding: 6px 20px;
            overflow-x: hidden;
            width: auto;
            text-overflow: ellipsis;
            &.active, &:active{
                background-color: $vve-oncall-selected-dept;
                color: $vve-black;
            }
        }
    }
}