.vve-service-now-container {
    
    .vve-snow-header {
        p {
            width: 75%;
        }
        @extend .vve-bold;
        font-weight: 800;
        font-size: 1rem;
        display: inline-flex;
        width: 100%;
        line-height: 0rem;
    }
    .vve-bck-btn {
        text-align: right;
        width: 20%;
    }

    .vve-snow-content {
        @extend .vve-medium;
        font-size: 1rem;
        margin-bottom: 0.2rem;
    }

    .vve-snow-disable-content {
        @extend .vve-light;
        font-size: 1rem;
        margin-bottom: 1rem;
        text-overflow: ellipsis;
    }

    .vve-snow-info {
        @extend .vve-lightitalic;
        font-size: 1rem;
    }

    .vve-text-right {
        text-align: right;
        margin-right: 2.5rem;
    }
    input[type="search"][class~="is-invalid"]::-webkit-search-cancel-button {
        -webkit-appearance: none;
        height: 1.5rem;
        width: 1.5rem;
        display: block;
        background-image: url("/assets/images/vve-error-cancel-search.svg");
        background-repeat: no-repeat;
        background-size: 1.5rem;
        opacity: 0.5;
    }
    .vve-back-btn {
        color: $kp-blue;
        @media screen and ($mobile-landscape){
            padding: 0 1.25rem;
        }
        padding: 0 1rem;
    }

    .vve-back-btn:focus, .vve-back-btn:focus-visible,
    .vve-bck-btn:focus, .vve-bck-btn:focus-visible {
        outline: none;
    }
    
    .vve-form-label {
        @extend .vve-medium;
        font-size: .5rem;
        margin-bottom: 0.2rem;

        input:enabled:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 1000px $vve-white inset;
        }

        input:disabled:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 1000px $input-disabled inset;
        }

        input {
            border-radius: 0.6rem;
            line-height: 2rem;
        }
        
        input[type="search"][class~="snow-contact-not-empty"]::-webkit-search-cancel-button {
            opacity: 1 !important;
            cursor: pointer;
        }

        .vve-text-copied {
            @extend .vve-medium;
            color: $vve-white;
            background-color: $vve-black;
            padding: 7px 14px;
            margin-top: -8px;
            font-size: 1rem;
            position: absolute;
            left: 0px;
            right: auto;
            width: auto;
            
            @media ($mobile) {
                display: inline-flex;
        
            }
            @media (orientation: landscape) {
                padding: 3px 14px;
            }
        
            
        }
        .vve-tool-tip-container {
            position: relative;
            left: 0px;
        }
    }

    .vve-description-box {
        margin-top: 1rem;
    }

    .vve-snow-textarea {
        resize: none;
    }

    .vve-textarea-right {
        text-align: right;
        margin-right: 0;
    }

    .vve-red-border {
        border-color: $vve-bright-red;
        color: $vve-bright-red;
    }
    .vve-snow-text {
        margin-bottom: 1rem;
    }

    .dropdown-toggle:after {
        content: none;
    }

    .vve-category-header {
        background-image: url("/assets/images/down-arrow.svg");
        background-position: 98% 55%;
        background-repeat: no-repeat;
        cursor: pointer;
        border: 1px solid $snow-category-header-border;
        border-radius: 0.6rem;
        width: 100%;
        text-align: left;
        line-height: 2rem;
        color: $vve-black;
    }

    .vve-category-list {
        background-color: $vve-white;
        width: 100%;
        border: 1px solid $snow-category-list-border;
        line-height: 2.5rem;
    }

    .vve-category-item {
        position: relative;
        padding-left: 3rem;
    }

    .vve-category-item.active {
        background-color: $vve-tabs-color-blue-active;
        color: $vve-white;
    }

    .vve-category-item.active::before {
        content: url("/assets/images/vve-checked.svg");
        position: absolute;
        display: inline-block;
        vertical-align: middle;
        line-height: 3.3;
        z-index: 1;
        left: .7rem;
        top: 0rem;
    }

    .vve-category-item:hover {
        cursor: pointer;
    }
      
    .vve-category-item-mobile-landscape.active::before {
        @media (orientation: landscape) {
        content: url("/assets/images/vve-category-checked.svg");
        position: absolute;
        display: inline-block;
        vertical-align: middle;
        line-height: 3.3;
        z-index: 1;
        left: .7rem;
        top: 0rem;
        }
     }
}