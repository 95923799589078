.vve-clinician-list {
    overflow-y: auto;
    max-height: calc(100vh - 200px);

    @media ($mobile) {
        width: 100%;
        max-height: 54.5rem;
    }

    .vve-clinician-panel {
        line-height: 11px;

        @media ($mobile) {
            width: 74vw;
        }

        .vve-clinician-name {
            font-size: 16px;
            display: flex;
            font-size: 16px;
            height: 18px;
            line-height: 20px;
            margin: 0;
            color: $vve-black-btn-font;

            p {
                font-family: 'Lato-Bold';
                font-weight: 500;
            }

            p:first-child {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                height: 100%;

                @media ($mobile) {
                    max-width: none;
                }
            }

            .vve-clinician-oncall {
                display: none;
            }

        }

        &.vve-clinician-oncall-live {
            .vve-clinician-oncall {
                background: $vve-oncall-green;
                border-radius: 7px;
                color: $vve-white;
                display: block;
                font-size: 10px;
                height: 15px;
                margin-left: 0.5rem;
                padding-left: 0.35rem;
                padding-right: 0.35rem;
                position: relative;
                bottom: 2px;
            }
        }

        .vve-clinician-role {
            margin: 0;
            color: $vve-caregiver-role-grey;
            line-height: 16px;
            font-family: 'Lato-Bold';
            font-size: 11px;
            font-weight: 500;
        }

        .vve-clinician-medcentre {
            font-size: 11px;
            margin: 0;
            color: $vve-caregiver-role-grey;
            line-height: 16px;
            font-weight: 500;
        }

        .vve-clinician-region {
            font-size: 11px;
            margin: 0;
            color: $vve-caregiver-role-grey;
            line-height: 16px;
            font-weight: 500;
        }

    }

    .vve-clinician-selected-caregiver {
        background-color: $vve-oncall-selected-dept;
    }

    .vve-clinician-icon-container {

        width: 48px;
        height: 48px;

        &.vve-clinician-oncall-live {
            background: $vve-oncall-green;
            border-radius: 50%;

            .vve-clinician-icon {
                max-width: 44px;
                width: 44px;
                height: 44px;
                border: 2.5px solid $vve-white;
                border-radius: 50%;
                margin: 2px;
            }
        }

        .vve-clinician-icon {
            max-width: 40px;
            width: 40px;
            height: 40px;
            margin: 3px;
            border-radius: 50%;
        }
    }

    /* overrides */
    .list-group-item:first-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .list-group-item {
        border-left: none;
        border-right: none;
        &.list-group-item-action {
            padding-left: 0;
            padding-right: 0;

            .align-items-center {
                padding-left: 11px;
                padding-right: 11px;
            }
        }
    }
    .vve-unfilledStar-icon {
        width: 15px;
        height: 15px;
        position: absolute;
        top: 50%;
        right: 3rem;
        transform: translateY(-50%);

        @media ($mobile) {
            right: 2rem;
        }

    }
    .vve-filledStar-icon {
        width: 15px;
        height: 15px;
        position: absolute;
        top: 50%;
        right: 3rem;
        transform: translateY(-50%);

        @media ($mobile) {
            right: 2rem;
        }

    }
}

.vve-clinician-no-results-found {
    background-image: url("../../assets/images/no_result_found.svg");
    background-repeat: no-repeat;
    height: 220px;
    top: 12rem;
    position: relative;
    background-size: contain;
    background-position: center center;
}


.vve-clinician-icon-container {
    height: auto;
}

.vve-clinician-list-container {
    height: 721px;
}
