.vve-zoom {
    border-radius: 1rem;
    font-size: 1.5rem;

    .modal-dialog {

        /*center the div to screen */
        display: flex;
        justify-content: center;
        width: 100%;
        height: 90%;
        align-items: center;
        margin: auto;

        /*end*/
    }

    .modal-header {
        border-bottom: none;
        line-height: 3.2rem;
        font-size: 2.4rem;
        padding-bottom: unset;
        justify-content: center;
    }

    .modal-footer {
        border-top: none;
        padding-top: 0rem;
    }

    .modal-content {
        width: 28.1rem;
        min-height: 20rem;
        height: auto;
        border-radius: 1rem;

        @media($mobile) {
            width: 27rem;
            min-height: 13.4rem;
        }
    }
    .modal-content p{
        @extend .vve-medium;
        font-size: 1rem;
        text-align: center;
    }

    .modal-title {
        text-align: center;
        padding-top: 3rem;
        line-height: 2.9rem;
        width: 75%;
        @extend .vve-bold;
    }
    .modal-title.h4{
        font-size: 2.2rem !important;
    }

    .modal-body {
        padding: 0.5rem 3rem;
        line-height: 2.2rem;
        text-align: center;
        font-size: 1.1rem;
        @extend .vve-bold;
        padding-bottom: 0rem;
    }

    .vve-description-container {
        display: flex;
        justify-content: space-between;
        padding: 0px 2rem;
        .vve-left-description {
            text-align: left;
        }
        .vve-right-description {
            text-align: right;
        }
    }


    .vve-zoom-modal-footer {
        text-align: center;
        width: 100%;
        margin-top: 0rem;
        button {
            font-size: 1.5rem;
            width: 8rem;
            margin: 2rem;
            height: 3.2rem;
            background-color: $vve-modal-btn;
            border-radius: 0.8rem;
        }

        .vve-cancel-button {
            background: none;
            color: inherit;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
            margin-top: -16px;
        }
    }
}
