.vve-directory {
  overflow-x: auto;
  height: 100vh;

  @media ($mobile) {
    overflow-x: hidden;
  }

  .vve-directory-tab-content-container {
    @extend .vve-bold;
    width: 42rem;
    margin: auto;

    @media screen and ($pixel-ratio: 1) and ($mobile) {
      width: 100%;
    }

    padding: 1.2rem 1.6rem 0 1.6rem;

    @media ($mobile) {
      &.mobile-hide {
        display: none;
      }
    }

    .vve-add-tabs {
      background: $vve-tabs-bgcolor-grey-inactive;
      color: $vve-vivid-blue;
      border-radius: 1rem;
      box-shadow: 0px 1px 4px rgb(0 0 0 / 8%);
      padding: 2px;
      margin-bottom: 0.6rem;

      @media ($mobile) {
        margin: 0;
      }

      .nav-item {
        border-radius: 1rem;
        .vve-add-tabitem {
          color: $vve-tabs-color-black-inactive;
          line-height: 3rem;

          &.nav-link {
            font-size: 1.1rem;
            line-height: 1.3rem;
            color: $vve-black;

            @media ($mobile) {
              line-height: 1.3rem;
            }

            &.active {
              border-radius: 1rem;
              color: $vve-tabs-color-blue-active;
              margin-bottom: 0;
              padding: 7px;
            }
          }
        }

        &:first-child {
          .vve-add-tabitem {
            &:hover {
              &:not(.active) {
                border-top-left-radius: 1.5rem;
                border-bottom-left-radius: 1.5rem;
                border-color: transparent;
              }
            }
          }
        }

        &:nth-child(2) {
          .vve-add-tabitem {
            &:hover {
              &:not(.active) {
                border-top-right-radius: 1.5rem;
                border-bottom-right-radius: 1.5rem;
                border-color: transparent;
              }
            }
          }
        }
      }
    }

    .vve-add-mobile-tabs {
      .nav-item {
        .vve-add-tabitem {
          &.nav-link {
            height: 4vh;
            line-height: 1.4rem;
          }
        }
      }
    }

    .tab-content {
      height: calc(100% - 4.4rem);
      font-size: 1.4rem;

      .vve-tab-panel {
        &.active {
          height: 100%;
        }
      }

      input[type="search"]::-webkit-search-cancel-button {
        -webkit-appearance: none;
        height: 1.5rem;
        width: 1.5rem;
        display: block;
        background-image: url("../../assets/images/vve-cancel-icon.svg");
        background-repeat: no-repeat;
        background-size: 1.5rem;
      }
    }

    @media ($mobile) {
      align-self: center;
    }
  }
}