.vve-filter-modal-root {
  .modal-dialog {
    max-width: 100%;
  }

  .modal-content {
    position: relative;
    max-width: 100%;
    width: 587px;
    padding: 1.7rem;

    @media($mobile) {
      width: 30rem;
      height: fit-content;
      max-width: 95%;
    }
  }

  .modal-header {
    justify-content: flex-start;
    padding: 0;
    margin-bottom: 0.5rem;

    .modal-title {
      text-align: left;
      padding-top: 0;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: -0.6px;
      color: $vve-black-btn-font;

      @media($mobile) {
        width: 100%;
      }
    }
  }

  .modal-body {
    padding: 0;
    font-size: 1.2rem;
  }

  .modal-footer {
    padding: 0;
    margin: 0 2px;
    margin-top: 2.8rem;
  }

  .vve-filter-modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;

    .vve-filter-modal-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @media($mobile) {
        flex-direction: column-reverse;
        width: 100%;
      }
    }

    button {
      margin: 0.9rem 0.5rem;
      width: auto;
      height: auto;
      min-width: 132px;
      font-size: 1.3rem;
      background-color: $vve-vivid-blue;
      font-family: 'Lato-Regular';
      font-weight: 500;
      color: $vve-white;

      &.btn-secondary {
        background: $vve-white;
        border: 1px solid $vve-black-btn-font;
        color: $vve-black-btn-font;
      }

      &:disabled {
        border: none;
        background-color: $button-disabled;
        color: $button-disabled-font;
      }

      @media($mobile) {
        width: 100%;
        margin: 0.5rem;
        height: 40px;
      }
    }

    @media($mobile) {
      flex-direction: column-reverse;
    }
  }
}