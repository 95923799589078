.vve-support-numbers-container {
    .vve-phone-numbers {
        list-style: none;
        padding: 0;
        width: 98%;
        margin-left: auto;
        margin-right: auto;
    }

    .vve-state {
        @extend .vve-medium;
        display: inline-block;
    }

    .vve-phoneNumber {
        text-align: right;
        @extend .vve-bold;

        font-size: 1rem;
    }

    .vve-support-content {
        @extend .vve-medium;
        font-size: 1rem;
    }
    .vve-copy-text {
        position: relative;
    }
    .vve-copy-text > div {
        @extend .vve-medium;
        font-size: 1rem;
        position: absolute;
        right: 10rem;
        width: max-content;
    }
    
    .vve-copypaste-icon {
        padding-right: .5rem;
    }

    .vve-ruler {
        margin: 0.3rem;
    }
    .vve-tooltip {
        position: absolute;
        right: 15rem;
        background: #D9D9D9;
        padding: 2rem;
        border-radius: 1rem;
        @extend .vve-medium;
    }
    margin-top: 1rem;
}
