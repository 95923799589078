.vve-directory-container {
  margin: 20px;

  @media ($mobile) {
    margin: 0;
  }

  .vve-oncall-container {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    width: 1436px;
    margin-left: auto;
    margin-right: auto;
    @media ($mobile) {
      width: auto;
    }
  }

  .vve-oncall-column {
    margin-right: 24px;

    @media ($mobile) {
      margin: 0;
    }

    &.vve-oncall-department {
      width: 353px;

      @media ($mobile) {
        width: 100%;

        &.mobile-hide {
          display: none;
        }
      }
    }

    &.vve-oncall-provider-list {
      flex-shrink: 0;
      width: 331px;

      @media ($mobile) {
        width: 100vw;

        &.mobile-hide {
          display: none;
        }
      }
    }

    &.vve-oncall-provider-details {
      width: 680px;
      flex-shrink: 0;

      @media ($mobile) {
        width: 100vw;

        &.mobile-hide {
          display: none;
        }
      }
    }
  }
}