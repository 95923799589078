.vve-clinician-directory {
  margin: 20px;

  &.vve-only-clinician {
    margin-top: 65px;

    @media ($mobile) {
      margin: 0;
    }
  }

  @media ($mobile) {
    width: 100%;
    margin: 0;
  }

  .vve-clinician-container {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    width: 1436px;
    margin-left: auto;
    margin-right: auto;

    @media ($mobile) {
      width: auto;
    }
  }

  .vve-clinician-column {
    min-height: 500px;
    margin-right: 8px;
    height: 50%;
    flex: 1;

    @media ($mobile) {
      width: 100%;
      margin-right: 0;

      &.mobile-hide {
        display: none;
      }
    }

  }
}

.vve-common-loader {
  &.vve-loader-custom {
    top: 0px;
    width: 710px;
    z-index: 1000;
    position: absolute;

      @media ($mobile) {
          width: 100%;
      }
  }
}
