.vve-main-container {
  .vve-container-height {
    height: 99%;
}
  .vve-landscape-container {
    margin: auto;
  }

  &.container-fluid {
    display: flex;
    flex-direction: column;
    // padding: 1.2rem 1.6rem;
    width: 100%;
    max-width: 100%;
    height: 100vh;

    hr {
      border-top: 1px solid $border-silver;
      opacity: 1;
    }
    .vve-main-ruler {
      margin: 0 0 1rem 0;
      width: 120rem;
    }
    @media screen and ($pixel-ratio: 1) and ($device-medium) {
      .vve-mobile-width {
        width: 100%;
        line-height: 4rem;
        height: 8vh;
        @media screen and ($mobile-landscape){
          line-height: 6rem;
        }
      }
    }
    @media screen and ($pixel-ratio: 1) and ($device-large) {
      .vve-mobile-width {
        width: 35rem;
        line-height: 4rem;
        height: 8vh;
        margin: auto;
      }
    }
  }
}
.vve-snow-height {
  height: calc(100% - 12vh);
}