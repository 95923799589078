.vve-invite-btn-container {
    width: 35rem;
    @media screen and ($pixel-ratio: 1) and ($device-medium) {
        width: 100%;
    }
    margin-left: auto;
    margin-right: auto;
    height: 7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    button.btn {
        width: 6.9rem;
        height: 3.1rem;
        background-color: $vve-vivid-blue;
        border-radius: 0.8rem;
        font-size: 1.5rem;
        line-height: 2.9rem;
        @extend .vve-bold;
        margin: 1.5rem auto;
        padding: 0;
        &:disabled{
            background-color: $button-disabled;
            color: $button-disabled-font;
            border-color: $button-disabled;
            opacity: 1;
        }
    }
}