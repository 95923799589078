.vve-join-container {
    position: relative;
    display: flex;
    flex-direction: column;

    .vve-kpgroup-logo {
        background-image: url("../../assets/images/Group-KP-Logo@1x.svg");
        background-repeat: no-repeat;
        height: 30rem;
        background-position: center;
        background-size: contain;
        width: 100%;
        margin-top: 7.5rem;

        @media($mobile) {
            height: 13.4rem;
            margin-top: 3rem;
        }
    }
}