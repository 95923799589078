.vve-add-portal-app-support-container {
    .vve-portal-app-support-main-header {
        @extend .vve-bold;
        font-size: 1.2rem;
    }

    .vve-portal-app-support-header {
        @extend .vve-bold;
        font-weight: 800;
        font-size: 1rem;
    }

    .vve-portal-app-support-content {
        @extend .vve-medium;
        font-size: 1rem;
        margin-bottom: 0.2rem;
        .vve-portal-app-show-inline {
            display: inline;
        }
    }

    .vve-portal-app-support-info {
        @extend .vve-lightitalic;
        font-size: 1rem;
    }

    .vve-portal-app-text-right {
        text-align: right;
    }
}
.vve-zoom .vve-description-container {
    line-height: .8;
}
.vve-zoom .vve-zoom-modal-footer button {
    margin-top: 0;
}
.vve-zoom .modal-body {
    line-height: 1.5rem;
    padding-bottom: 1.0rem;
}