.vve-regionlist-container {
    height: 100%;
    padding: 0 10px;
    min-width: 30rem;
    @media screen and ($mobile-landscape) {
        margin: auto;
    }
}

.vve-region-list {
    height: calc(100% - 25rem);
    overflow-y: auto;
    font-size: 13px;
    max-width: 22.5rem;
    @extend .vve-bold;
    button {
        border: none;
        &.active {
            background: $vve-vivid-blue;
            border-radius: 1rem;
        }
    }
}

.region-list-header {    
    font-size: 13px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.2px;
    margin: 20px 0 10px 0;
    @extend .vve-medium;
}

.vve-select-btn-container {
    position: absolute;
    bottom: 30px;
    width: calc(100% - 40px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #ccc;
    padding-top: 30px;
    @extend .vve-medium;
    @media screen and ($desktop-medium) {
        justify-content: left;
    }
    @media screen and ($desktop-large) {
        justify-content: left;
    }
    

    .button {
        background-color: $vve-vivid-blue;
        border-color: $vve-vivid-blue;
        border-radius: 0.8rem;
        font-size: 1.5rem;
        line-height: 2.9rem;
        color: $vve-white;
        padding: 0 15px;
        &:disabled{
            background-color: $button-disabled;
            color: $button-disabled-font;
            border-color: $button-disabled;
            opacity: 1;
        }
        @media screen and ($mobile-landscape) {
            width: 23rem;
            font-size: 13px;
            padding: 3px 10px;
        }
        @media screen and ($desktop-medium){
            margin-left: 5rem;
            line-height: 2.3rem;
        }
        @media screen and ($desktop-large){
            margin-left: 5rem;
            line-height: 2.3rem;
        }
    }
    @media screen and ($mobile-landscape) {
        width: 100vw;
        left: 0;
        right: 0;
    }
}