.vve-zoom {
    &.multiInviteFailedModal {
        .modal-body {
            .lastNotification {
                padding-bottom: 2.4rem;
            }
        }
    }

    &.loaderModalRoot {
        .modal-header {
            padding: 7rem 0;
            .modal-title {
                &.h4 {
                    padding-top: 0;
                }
            }
        }
        .modal-body {
            padding: 0;
        }
    }

    &.serviceDownModal {
        .modal-body {
            line-height: 2.5rem;
            padding-bottom: 3rem;
        }
    }
}