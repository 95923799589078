.vve-search-container{
    .vve-search-input {
        color: $vve-light-black;
        border-radius: 1rem;
        @extend .vve-medium;
        font-size: 1.4rem;
        margin-bottom: 2rem;
        padding-left: 4rem;
    }
    input::placeholder {
        @extend .vve-light;
    }
}