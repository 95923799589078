.vve-modal-root {
    .vve-zoom-modal-footer {
        .btn.vve-loader-action {
            background-color: $vve-modal-btn;
            color: $vve-white;
        }
    }
    .modal-content {
        padding: 0.1rem;
        .vve-connecting-body {
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }
    .modal-footer {
        border-top: none;
        padding-top: 0.1rem;
    }
}
