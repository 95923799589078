html {
    font-size: 1.5vh;
    line-height: 1;
    height: 100%;
    touch-action: manipulation;
}
@media screen and (max-width: 844px) and (orientation: landscape){
  html {
    font-size: 2vh;
  }
}
body {
    height: 100%;
    overflow-x: hidden;
}

input textarea {
  max-width: 100%;
}

$weight-array:( book: "Lato-Thin", light: "Lato-Light", medium: "Lato-Regular", bold: "Lato-Bold", black: "Lato-Black", bookitalic: "Lato-ThinItalic", lightitalic: "Lato-LightItalic", mediumitalic: "Lato-Italic", bolditalic: "Lato-BoldItalic", blackitalic: "Lato-BlackItalic", kpbook: "Gotham 4r", kplight: "Gotham 3r", kpmedium: "Gotham 5r", kpbold: "Gotham 7r");

@each $weight-name,
  $weight-value in $weight-array {
  // single-dash is preferred, but will support double-dash for legacy implementations
  .vve-#{$weight-name},
  .\--#{$weight-name} { // escpaed double hyphen for IE and legacy mobile Safari suport
    font-family: $weight-value;
  }
}